import React from "react";
import Helmet from "react-helmet"
import { withPrefix, Link } from "gatsby"
import Layout from "../components/layout";
import jQuery from 'jquery'; 
import { useStaticQuery, graphql } from "gatsby";



 

const ConcernList = () => {

    return(
    
    <Layout>
    <Helmet>
        <script src="https://www.carrotstick.com/wp-content/themes/xejic_carrot_stick/assets/js/custom.js" type="text/javascript" />
    </Helmet>

<div class="taxonomy-page">
        <div class="container-fluid cs-concerns new-concerns-section">
        <div class="concerns-list-group">
            <div class="row">
                <div class="col-12 col-md-4">
                    <div class="link-list new-link-lists">
                        <div class="head">
                            Concerns
                        </div>
                        <div class="list-group cs-list-tab" id="list-tab" role="tablist">
                                                                <a class="list-group-item list-group-item-action active" id="loss-of-elasticity-firmness-list" data-toggle="list" href="#list-loss-of-elasticity-firmness" role="tab" aria-controls="loss-of-elasticity-firmness">
                                        <span>Loss of Elasticity &amp; Firmness<sup></sup></span>
                                    </a>
                                                                                            <a class="list-group-item list-group-item-action " id="lines-wrinkles-list" data-toggle="list" href="#list-lines-wrinkles" role="tab" aria-controls="lines-wrinkles">
                                        <span>Lines &amp; Wrinkles<sup></sup></span>
                                    </a>
                                                                                            <a class="list-group-item list-group-item-action " id="first-signs-of-aging-list" data-toggle="list" href="#list-first-signs-of-aging" role="tab" aria-controls="first-signs-of-aging">
                                        <span>First Signs of Aging<sup></sup></span>
                                    </a>
                                                                                            <a class="list-group-item list-group-item-action " id="dryness-dehydration-list" data-toggle="list" href="#list-dryness-dehydration" role="tab" aria-controls="dryness-dehydration">
                                        <span>Dryness &amp; Dehydration<sup></sup></span>
                                    </a>
                                                                                            <a class="list-group-item list-group-item-action " id="acne-blemishes-list" data-toggle="list" href="#list-acne-blemishes" role="tab" aria-controls="acne-blemishes">
                                        <span>Acne &amp; Blemishes<sup></sup></span>
                                    </a>
                                                                                            <a class="list-group-item list-group-item-action " id="oily-skin-list" data-toggle="list" href="#list-oily-skin" role="tab" aria-controls="oily-skin">
                                        <span>Oily Skin<sup></sup></span>
                                    </a>
                                                                                            <a class="list-group-item list-group-item-action " id="redness-sensitivity-list" data-toggle="list" href="#list-redness-sensitivity" role="tab" aria-controls="redness-sensitivity">
                                        <span>Redness &amp; Sensitivity<sup></sup></span>
                                    </a>
                                                                                            <a class="list-group-item list-group-item-action " id="dull-uneven-skin-tone-list" data-toggle="list" href="#list-dull-uneven-skin-tone" role="tab" aria-controls="dull-uneven-skin-tone">
                                        <span>Dull, Uneven skin tone<sup></sup></span>
                                    </a>
                                                                                </div>
                    </div>
                </div>
                <div class="col-12 col-md-8">
                    <div class="content-list">
                        <div class="tab-content" id="nav-tabContent">
                                                            <div class="tab-pane fade show active" id="list-loss-of-elasticity-firmness" role="tabpanel" aria-labelledby="loss-of-elasticity-firmness-list">
                                    <div class="row cs-content">
                                                                                        <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card concer-product-block">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                        
                                                            <a href="https://www.carrotstick.com/product/the-repair-serum/" class="img">
                                                                                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2021/11/RepairSerum_@6x-240x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-repair-serum/" class="name">
                                                                The Repair Serum                                                            </a>
                                                            <div class="new-product-rating"> 
                                                                <div class="review-stars-cntnr">
                                                                     <div id="category-snippet-111" data-pr-component="CategorySnippet" data-pr-page_id="CS-THREPAIRSERUM" data-pr-mounted="true"><div class="p-w-r" ><section id="pr-category-snippets-CS-THREPAIRSERUM" class="" aria-labelledby="pr-JGssn_YIYg2ubDnAMn2SD" data-testid="category-snippet"><div class="pr-snippet pr-category-snippet"><div class="pr-category-snippet__rating pr-category-snippet__item"><div class="pr-snippet-stars pr-snippet-stars-png" role="img" aria-label="Rated 4.6 out of 5 stars"><div aria-hidden="true" class="pr-rating-stars"><div class="pr-star-v4 pr-star-v4-100-filled"></div><div class="pr-star-v4 pr-star-v4-100-filled"></div><div class="pr-star-v4 pr-star-v4-100-filled"></div><div class="pr-star-v4 pr-star-v4-100-filled"></div><div class="pr-star-v4 pr-star-v4-50-filled"></div></div><div aria-hidden="true" class="pr-snippet-rating-decimal">4.6</div></div></div><div class="pr-category-snippet__total pr-category-snippet__item">12 Reviews</div></div></section></div></div>
                                                                </div> 

                                                            </div>
                                                            <div class="overview">
                                                                This high-performance serum is designed to lift, tighten and firm...                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>80</bdi></span>                                                            </div>
                                                            
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-repair-serum/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card concer-product-block">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            
                                                            <a href="https://www.carrotstick.com/product/the-defense-serum/" class="img">
                                                                                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2020/11/DefenceSerum_@6x-240x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-defense-serum/" class="name">
                                                                The Defense Serum                                                            </a>
                                                            <div class="new-product-rating"> 
                                                                <div class="review-stars-cntnr">
                                                                     <div id="category-snippet-20" data-pr-component="CategorySnippet" data-pr-page_id="CS-TDEFENSESERUM" data-pr-mounted="true"><div class="p-w-r" ><section id="pr-category-snippets-CS-TDEFENSESERUM" class="" aria-labelledby="pr-POsJAV9AgXOUv062Ge8v3" data-testid="category-snippet"><div class="pr-snippet pr-category-snippet"><div class="pr-category-snippet__rating pr-category-snippet__item"><div class="pr-snippet-stars pr-snippet-stars-png" role="img" aria-label="Rated 5.0 out of 5 stars"><div aria-hidden="true" class="pr-rating-stars"><div class="pr-star-v4 pr-star-v4-100-filled"></div><div class="pr-star-v4 pr-star-v4-100-filled"></div><div class="pr-star-v4 pr-star-v4-100-filled"></div><div class="pr-star-v4 pr-star-v4-100-filled"></div><div class="pr-star-v4 pr-star-v4-100-filled"></div></div><div aria-hidden="true" class="pr-snippet-rating-decimal">5.0</div></div></div><div class="pr-category-snippet__total pr-category-snippet__item">4 Reviews</div></div></section></div></div>
                                                                </div> 

                                                            </div>
                                                            <div class="overview">
                                                                Packed with the antioxidant superpower of three vitamin C derivatives...                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>80</bdi></span>                                                            </div>
                                                            
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-defense-serum/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card concer-product-block">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            
                                                            <a href="https://www.carrotstick.com/product/the-moisturizer/" class="img">
                                                                                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2021/11/Moisturizer_@6x-240x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-moisturizer/" class="name">
                                                                The Moisturizer                                                            </a>
                                                            <div class="new-product-rating"> 
                                                                <div class="review-stars-cntnr">
                                                                     <div id="category-snippet-22" data-pr-component="CategorySnippet" data-pr-page_id="CS-TRUEMOISTRIZR" data-pr-mounted="true"><div class="p-w-r" ><section id="pr-category-snippets-CS-TRUEMOISTRIZR" class="" aria-labelledby="pr-ioIQ74BFNBA6AUMojk9e1" data-testid="category-snippet"><div class="pr-snippet pr-category-snippet"><div class="pr-category-snippet__rating pr-category-snippet__item"><div class="pr-snippet-stars pr-snippet-stars-png" role="img" aria-label="Rated 4.3 out of 5 stars"><div aria-hidden="true" class="pr-rating-stars"><div class="pr-star-v4 pr-star-v4-100-filled"></div><div class="pr-star-v4 pr-star-v4-100-filled"></div><div class="pr-star-v4 pr-star-v4-100-filled"></div><div class="pr-star-v4 pr-star-v4-100-filled"></div><div class="pr-star-v4 pr-star-v4-25-filled"></div></div><div aria-hidden="true" class="pr-snippet-rating-decimal">4.3</div></div></div><div class="pr-category-snippet__total pr-category-snippet__item">9 Reviews</div></div></section></div></div>
                                                                </div> 

                                                            </div>
                                                            <div class="overview">
                                                                This lightweight antioxidant formula is a hydrating powerhouse...                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>60</bdi></span>                                                            </div>
                                                            
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-moisturizer/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card concer-product-block">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                           
                                                            <a href="https://www.carrotstick.com/product/the-eye-cream/" class="img">
                                                                                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2021/11/EyeCream_@6x-240x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-eye-cream/" class="name">
                                                                The Eye Cream                                                            </a>
                                                            <div class="new-product-rating"> 
                                                                <div class="review-stars-cntnr">
                                                                     <div id="category-snippet-14" data-pr-component="CategorySnippet" data-pr-page_id="CS-THEEYECREAM" data-pr-mounted="true"><div class="p-w-r" ><section id="pr-category-snippets-CS-THEEYECREAM" class="" aria-labelledby="pr-py6RY-2qfVcsUYKkSNXLW" data-testid="category-snippet"><div class="pr-snippet pr-category-snippet"><div class="pr-category-snippet__rating pr-category-snippet__item"><div class="pr-snippet-stars pr-snippet-stars-png" role="img" aria-label="Rated 4.8 out of 5 stars"><div aria-hidden="true" class="pr-rating-stars"><div class="pr-star-v4 pr-star-v4-100-filled"></div><div class="pr-star-v4 pr-star-v4-100-filled"></div><div class="pr-star-v4 pr-star-v4-100-filled"></div><div class="pr-star-v4 pr-star-v4-100-filled"></div><div class="pr-star-v4 pr-star-v4-75-filled"></div></div><div aria-hidden="true" class="pr-snippet-rating-decimal">4.8</div></div></div><div class="pr-category-snippet__total pr-category-snippet__item">5 Reviews</div></div></section></div></div>
                                                                </div> 

                                                            </div>
                                                            <div class="overview">
                                                                This revolutionary treatment uses highly effective botanicals...                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>60</bdi></span>                                                            </div>
                                                            
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-eye-cream/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col last-big-card  cs-col">
                                                    <div class="product-card concer-product-block">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            
                                                            <a href="https://www.carrotstick.com/product/the-gentle-hydrating-cleanser/" class="img">
                                                                                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2022/06/The-Gentle-Hydrating-Cleanser-300x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-gentle-hydrating-cleanser/" class="name">
                                                                The Gentle Hydrating Cleanser                                                            </a>
                                                            <div class="new-product-rating"> 
                                                                <div class="review-stars-cntnr">
                                                                     <div id="category-snippet-12662" data-pr-component="CategorySnippet" data-pr-page_id="CS-GENTLEHGCLNSR" data-pr-mounted="true"><div class="p-w-r" ><section id="pr-category-snippets-CS-GENTLEHGCLNSR" class="pr-no-reviews" aria-labelledby="pr-KLTKRQCO8haz8IRZWQ0aR" data-testid="category-snippet"><div class="pr-snippet pr-category-snippet"><div class="pr-category-snippet__rating pr-category-snippet__item"><div class="pr-snippet-stars pr-snippet-stars-png" role="img" aria-label="Rated 0.0 out of 5 stars"><div aria-hidden="true" class="pr-rating-stars"><div class="pr-star-v4 pr-star-v4-0-filled"></div><div class="pr-star-v4 pr-star-v4-0-filled"></div><div class="pr-star-v4 pr-star-v4-0-filled"></div><div class="pr-star-v4 pr-star-v4-0-filled"></div><div class="pr-star-v4 pr-star-v4-0-filled"></div></div><div aria-hidden="true" class="pr-snippet-rating-decimal">0.0</div></div></div><div class="pr-category-snippet__total pr-category-snippet__item">No Reviews</div></div></section></div></div>
                                                                </div> 

                                                            </div>
                                                            <div class="overview">
                                                                <span>This gentle and effective oil-free cleanser is formulated with natural...</span>                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>40</bdi></span>                                                            </div>
                                                            
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-gentle-hydrating-cleanser/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                </div>
                                </div>
                                                            <div class="tab-pane fade " id="list-lines-wrinkles" role="tabpanel" aria-labelledby="lines-wrinkles-list">
                                    <div class="row cs-content">
                                                                                        <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card concer-product-block">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            
                                                            <a href="https://www.carrotstick.com/product/the-repair-serum/" class="img">
                                                                                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2021/11/RepairSerum_@6x-240x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-repair-serum/" class="name">
                                                                The Repair Serum                                                            </a>
                                                            <div class="new-product-rating"> 
                                                                <div class="review-stars-cntnr">
                                                                     <div id="category-snippet-111"></div>
                                                                </div> 

                                                            </div>
                                                            <div class="overview">
                                                                This high-performance serum is designed to lift, tighten and firm...                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>80</bdi></span>                                                            </div>
                                                            
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-repair-serum/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card concer-product-block">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            
                                                            <a href="https://www.carrotstick.com/product/the-defense-serum/" class="img">
                                                                                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2020/11/DefenceSerum_@6x-240x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-defense-serum/" class="name">
                                                                The Defense Serum                                                            </a>
                                                            <div class="new-product-rating"> 
                                                                <div class="review-stars-cntnr">
                                                                     <div id="category-snippet-20"></div>
                                                                </div> 

                                                            </div>
                                                            <div class="overview">
                                                                Packed with the antioxidant superpower of three vitamin C derivatives...                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>80</bdi></span>                                                            </div>
                                                            
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-defense-serum/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card concer-product-block">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            
                                                            <a href="https://www.carrotstick.com/product/the-moisturizer/" class="img">
                                                                                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2021/11/Moisturizer_@6x-240x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-moisturizer/" class="name">
                                                                The Moisturizer                                                            </a>
                                                            <div class="new-product-rating"> 
                                                                <div class="review-stars-cntnr">
                                                                     <div id="category-snippet-22"></div>
                                                                </div> 

                                                            </div>
                                                            <div class="overview">
                                                                This lightweight antioxidant formula is a hydrating powerhouse...                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>60</bdi></span>                                                            </div>
                                                            
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-moisturizer/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card concer-product-block">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            
                                                            <a href="https://www.carrotstick.com/product/the-eye-cream/" class="img">
                                                                                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2021/11/EyeCream_@6x-240x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-eye-cream/" class="name">
                                                                The Eye Cream                                                            </a>
                                                            <div class="new-product-rating"> 
                                                                <div class="review-stars-cntnr">
                                                                     <div id="category-snippet-14"></div>
                                                                </div> 

                                                            </div>
                                                            <div class="overview">
                                                                This revolutionary treatment uses highly effective botanicals...                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>60</bdi></span>                                                            </div>
                                                            
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-eye-cream/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col last-big-card  cs-col">
                                                    <div class="product-card concer-product-block">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            
                                                            <a href="https://www.carrotstick.com/product/the-gentle-hydrating-cleanser/" class="img">
                                                                                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2022/06/The-Gentle-Hydrating-Cleanser-300x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-gentle-hydrating-cleanser/" class="name">
                                                                The Gentle Hydrating Cleanser                                                            </a>
                                                            <div class="new-product-rating"> 
                                                                <div class="review-stars-cntnr">
                                                                     <div id="category-snippet-12662"></div>
                                                                </div> 

                                                            </div>
                                                            <div class="overview">
                                                                <span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;This gentle and effective oil-free cleanser is formulated with natural plant extracts and natural hydrators, including hyaluronic acid, that won’t strip precious oils, disrupting the surface barrier. Coconut-based surfactants work into a luxurious lather. Skin will look and feel nourished and soft to the touch. Antioxidant-packed ingredients help protect against environmental stressors that can cause the appearance of fine lines and wrinkles. Does not contain harsh sulfates that can irritate skin. Fragrance Free. Formulated with love for all skin types, including sensitive.\n&quot;}" data-sheets-userformat="{&quot;2&quot;:4481,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:0,&quot;11&quot;:4,&quot;15&quot;:&quot;Arial&quot;}">This gentle and effective oil-free cleanser is formulated with natural...</span>                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>40</bdi></span>                                                            </div>
                                                            
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-gentle-hydrating-cleanser/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                </div>
                                </div>
                                                            <div class="tab-pane fade " id="list-first-signs-of-aging" role="tabpanel" aria-labelledby="first-signs-of-aging-list">
                                    <div class="row cs-content">
                                                                                        <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card concer-product-block">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            
                                                            <a href="https://www.carrotstick.com/product/the-defense-serum/" class="img">
                                                                                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2020/11/DefenceSerum_@6x-240x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-defense-serum/" class="name">
                                                                The Defense Serum                                                            </a>
                                                            <div class="new-product-rating"> 
                                                                <div class="review-stars-cntnr">
                                                                     <div id="category-snippet-20"></div>
                                                                </div> 

                                                            </div>
                                                            <div class="overview">
                                                                Packed with the antioxidant superpower of three vitamin C derivatives...                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>80</bdi></span>                                                            </div>
                                                            
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-defense-serum/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card concer-product-block">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            
                                                            <a href="https://www.carrotstick.com/product/the-moisturizer/" class="img">
                                                                                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2021/11/Moisturizer_@6x-240x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-moisturizer/" class="name">
                                                                The Moisturizer                                                            </a>
                                                            <div class="new-product-rating"> 
                                                                <div class="review-stars-cntnr">
                                                                     <div id="category-snippet-22"></div>
                                                                </div> 

                                                            </div>
                                                            <div class="overview">
                                                                This lightweight antioxidant formula is a hydrating powerhouse...                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>60</bdi></span>                                                            </div>
                                                            
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-moisturizer/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card concer-product-block">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            
                                                            <a href="https://www.carrotstick.com/product/the-eye-cream/" class="img">
                                                                                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2021/11/EyeCream_@6x-240x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-eye-cream/" class="name">
                                                                The Eye Cream                                                            </a>
                                                            <div class="new-product-rating"> 
                                                                <div class="review-stars-cntnr">
                                                                     <div id="category-snippet-14"></div>
                                                                </div> 

                                                            </div>
                                                            <div class="overview">
                                                                This revolutionary treatment uses highly effective botanicals...                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>60</bdi></span>                                                            </div>
                                                            
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-eye-cream/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card concer-product-block">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            
                                                            <a href="https://www.carrotstick.com/product/the-mineral-sunscreen-spf-50-for-face/" class="img">
                                                                                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2022/06/The-Mineral-Sunscreen-SPF-50-1-1-300x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-mineral-sunscreen-spf-50-for-face/" class="name">
                                                                The Mineral Sunscreen SPF 50                                                            </a>
                                                            <div class="new-product-rating"> 
                                                                <div class="review-stars-cntnr">
                                                                     <div id="category-snippet-12343" data-pr-component="CategorySnippet" data-pr-page_id="CS-SUNSCRENSPF50" data-pr-mounted="true"><div class="p-w-r" ><section id="pr-category-snippets-CS-SUNSCRENSPF50" class="pr-no-reviews" aria-labelledby="pr-1brumU9sEqFWed6YKvCpv" data-testid="category-snippet"><div class="pr-snippet pr-category-snippet"><div class="pr-category-snippet__rating pr-category-snippet__item"><div class="pr-snippet-stars pr-snippet-stars-png" role="img" aria-label="Rated 0.0 out of 5 stars"><div aria-hidden="true" class="pr-rating-stars"><div class="pr-star-v4 pr-star-v4-0-filled"></div><div class="pr-star-v4 pr-star-v4-0-filled"></div><div class="pr-star-v4 pr-star-v4-0-filled"></div><div class="pr-star-v4 pr-star-v4-0-filled"></div><div class="pr-star-v4 pr-star-v4-0-filled"></div></div><div aria-hidden="true" class="pr-snippet-rating-decimal">0.0</div></div></div><div class="pr-category-snippet__total pr-category-snippet__item">No Reviews</div></div></section></div></div>
                                                                </div> 

                                                            </div>
                                                            <div class="overview">
                                                                <span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;This Broad Spectrum SPF 50 Facial Lotion is derived from and formulated with mineral sunscreen; there are no harsh chemicals that can harm skin and the environment, including our oceans. Specially formulated with Titanium Dioxide and Zinc Oxide to protect skin against the harmful effects of UVA and UVB which can hasten the effects of skin aging, it also contains natural hydrators and antioxidants including Vitamin E, Green Tea Extract and Hawaiian Kukui Nut Oil to keep skin nourished and smooth. No greasy feel after application and the water-resistant formula delivers protection throughout the day with reapplication. Formulated with love for all skin types.\n-Helps prevent sunburn\n-If used as directed with other sun protection measures (see Directions), deceases the risk of skin cancer and early skin aging caused by the sun.\n\nWARNINGS: For external use only. When using this product, keep out of eyes. Rinse with warm water to remove. Do not use on damaged or broken skin. Stop use and consult a doctor if irritation or rash occurs. Keep out of the reach of children. If swallowed, get medical help or contact a Poison Control Center immediately. Note: If allergy-prone, pregnant, or lactating, consult your physician before use. \n&quot;}" data-sheets-userformat="{&quot;2&quot;:4481,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:0,&quot;11&quot;:4,&quot;15&quot;:&quot;Arial&quot;}">This Broad Spectrum SPF 50 Facial Lotion is derived from and formulated...</span>                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>60</bdi></span>                                                            </div>
                                                            
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-mineral-sunscreen-spf-50-for-face/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col last-big-card  cs-col">
                                                    <div class="product-card concer-product-block">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            
                                                            <a href="https://www.carrotstick.com/product/the-gentle-hydrating-cleanser/" class="img">
                                                                                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2022/06/The-Gentle-Hydrating-Cleanser-300x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-gentle-hydrating-cleanser/" class="name">
                                                                The Gentle Hydrating Cleanser                                                            </a>
                                                            <div class="new-product-rating"> 
                                                                <div class="review-stars-cntnr">
                                                                     <div id="category-snippet-12662"></div>
                                                                </div> 

                                                            </div>
                                                            <div class="overview">
                                                                <span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;This gentle and effective oil-free cleanser is formulated with natural plant extracts and natural hydrators, including hyaluronic acid, that won’t strip precious oils, disrupting the surface barrier. Coconut-based surfactants work into a luxurious lather. Skin will look and feel nourished and soft to the touch. Antioxidant-packed ingredients help protect against environmental stressors that can cause the appearance of fine lines and wrinkles. Does not contain harsh sulfates that can irritate skin. Fragrance Free. Formulated with love for all skin types, including sensitive.\n&quot;}" data-sheets-userformat="{&quot;2&quot;:4481,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:0,&quot;11&quot;:4,&quot;15&quot;:&quot;Arial&quot;}">This gentle and effective oil-free cleanser is formulated with natural...</span>                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>40</bdi></span>                                                            </div>
                                                            
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-gentle-hydrating-cleanser/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                </div>
                                </div>
                                                            <div class="tab-pane fade " id="list-dryness-dehydration" role="tabpanel" aria-labelledby="dryness-dehydration-list">
                                    <div class="row cs-content">
                                                                                        <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card concer-product-block">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            
                                                            <a href="https://www.carrotstick.com/product/the-repair-cream-for-dry-skin/" class="img">
                                                                                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2022/06/The-Repair-Cream-300x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-repair-cream-for-dry-skin/" class="name">
                                                                The Repair Cream. For Dry Skin.                                                            </a>
                                                            <div class="new-product-rating"> 
                                                                <div class="review-stars-cntnr">
                                                                     <div id="category-snippet-12865" data-pr-component="CategorySnippet" data-pr-page_id="CS-THREPAIRCREAM" data-pr-mounted="true"><div class="p-w-r" ><section id="pr-category-snippets-CS-THREPAIRCREAM" class="pr-no-reviews" aria-labelledby="pr-xDM3RKHD2acnl5JckG7hD" data-testid="category-snippet"><div class="pr-snippet pr-category-snippet"><div class="pr-category-snippet__rating pr-category-snippet__item"><div class="pr-snippet-stars pr-snippet-stars-png" role="img" aria-label="Rated 0.0 out of 5 stars"><div aria-hidden="true" class="pr-rating-stars"><div class="pr-star-v4 pr-star-v4-0-filled"></div><div class="pr-star-v4 pr-star-v4-0-filled"></div><div class="pr-star-v4 pr-star-v4-0-filled"></div><div class="pr-star-v4 pr-star-v4-0-filled"></div><div class="pr-star-v4 pr-star-v4-0-filled"></div></div><div aria-hidden="true" class="pr-snippet-rating-decimal">0.0</div></div></div><div class="pr-category-snippet__total pr-category-snippet__item">No Reviews</div></div></section></div></div>
                                                                </div> 

                                                            </div>
                                                            <div class="overview">
                                                                <span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Experience a skincare cream, uniquely formulated to nurture and protect skin’s microbiome. The microbiome is the ecosystem of microbes that live on the skin surface. Happy microbes equal a healthy microbiome and a healthy microbiome equals healthier-looking, more radiant skin. If it becomes imbalanced, acne, eczema, dryness and other skin issues can appear. Our proprietary formulation of probiotics and natural, soothing plant extracts keep the microbiome operating at peak performance and skin looking hydrated and visible issues diminished. Fragrance Free. Formulated with love for all skin types, especially dry.\n&quot;}" data-sheets-userformat="{&quot;2&quot;:4480,&quot;10&quot;:0,&quot;11&quot;:4,&quot;15&quot;:&quot;Arial&quot;}">Experience a skincare cream, uniquely formulated to nurture and... </span>                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>60</bdi></span>                                                            </div>
                                                            
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-repair-cream-for-dry-skin/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card concer-product-block">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            
                                                            <a href="https://www.carrotstick.com/product/the-eye-cream/" class="img">
                                                                                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2021/11/EyeCream_@6x-240x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-eye-cream/" class="name">
                                                                The Eye Cream                                                            </a>
                                                            <div class="new-product-rating"> 
                                                                <div class="review-stars-cntnr">
                                                                     <div id="category-snippet-14"></div>
                                                                </div> 

                                                            </div>
                                                            <div class="overview">
                                                                This revolutionary treatment uses highly effective botanicals...                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>60</bdi></span>                                                            </div>
                                                            
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-eye-cream/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card concer-product-block">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            
                                                            <a href="https://www.carrotstick.com/product/the-gentle-hydrating-cleanser/" class="img">
                                                                                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2022/06/The-Gentle-Hydrating-Cleanser-300x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-gentle-hydrating-cleanser/" class="name">
                                                                The Gentle Hydrating Cleanser                                                            </a>
                                                            <div class="new-product-rating"> 
                                                                <div class="review-stars-cntnr">
                                                                     <div id="category-snippet-12662"></div>
                                                                </div> 

                                                            </div>
                                                            <div class="overview">
                                                                <span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;This gentle and effective oil-free cleanser is formulated with natural plant extracts and natural hydrators, including hyaluronic acid, that won’t strip precious oils, disrupting the surface barrier. Coconut-based surfactants work into a luxurious lather. Skin will look and feel nourished and soft to the touch. Antioxidant-packed ingredients help protect against environmental stressors that can cause the appearance of fine lines and wrinkles. Does not contain harsh sulfates that can irritate skin. Fragrance Free. Formulated with love for all skin types, including sensitive.\n&quot;}" data-sheets-userformat="{&quot;2&quot;:4481,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:0,&quot;11&quot;:4,&quot;15&quot;:&quot;Arial&quot;}">This gentle and effective oil-free cleanser is formulated with natural...</span>                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>40</bdi></span>                                                            </div>
                                                            
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-gentle-hydrating-cleanser/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card concer-product-block">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            
                                                            <a href="https://www.carrotstick.com/product/the-hydrating-serum/" class="img">
                                                                                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2022/02/The-Hydrating-Serum-300x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-hydrating-serum/" class="name">
                                                                The Hydrating Serum                                                            </a>
                                                            <div class="new-product-rating"> 
                                                                <div class="review-stars-cntnr">
                                                                     <div id="category-snippet-9451" data-pr-component="CategorySnippet" data-pr-page_id="CS-HYDRATINGSERM" data-pr-mounted="true"><div class="p-w-r" ><section id="pr-category-snippets-CS-HYDRATINGSERM" class="pr-no-reviews" aria-labelledby="pr-L85b8eqjqBgsbe8QiIOcz" data-testid="category-snippet"><div class="pr-snippet pr-category-snippet"><div class="pr-category-snippet__rating pr-category-snippet__item"><div class="pr-snippet-stars pr-snippet-stars-png" role="img" aria-label="Rated 0.0 out of 5 stars"><div aria-hidden="true" class="pr-rating-stars"><div class="pr-star-v4 pr-star-v4-0-filled"></div><div class="pr-star-v4 pr-star-v4-0-filled"></div><div class="pr-star-v4 pr-star-v4-0-filled"></div><div class="pr-star-v4 pr-star-v4-0-filled"></div><div class="pr-star-v4 pr-star-v4-0-filled"></div></div><div aria-hidden="true" class="pr-snippet-rating-decimal">0.0</div></div></div><div class="pr-category-snippet__total pr-category-snippet__item">No Reviews</div></div></section></div></div>
                                                                </div> 

                                                            </div>
                                                            <div class="overview">
                                                                                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>70</bdi></span>                                                            </div>
                                                            
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-hydrating-serum/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col last-big-card  cs-col">
                                                    <div class="product-card concer-product-block">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            
                                                            <a href="https://www.carrotstick.com/product/the-face-oil/" class="img">
                                                                                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2021/12/TheFaceOil@6x-240x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-face-oil/" class="name">
                                                                The Face Oil Retinoic                                                            </a>
                                                            <div class="new-product-rating"> 
                                                                <div class="review-stars-cntnr">
                                                                     <div id="category-snippet-4957" data-pr-component="CategorySnippet" data-pr-page_id="CS-THEFACEOIL" data-pr-mounted="true"><div class="p-w-r" ><section id="pr-category-snippets-CS-THEFACEOIL" class="" aria-labelledby="pr-OFaIKfaNFp-nvYZXgr1QC" data-testid="category-snippet"><div class="pr-snippet pr-category-snippet"><div class="pr-category-snippet__rating pr-category-snippet__item"><div class="pr-snippet-stars pr-snippet-stars-png" role="img" aria-label="Rated 5.0 out of 5 stars"><div aria-hidden="true" class="pr-rating-stars"><div class="pr-star-v4 pr-star-v4-100-filled"></div><div class="pr-star-v4 pr-star-v4-100-filled"></div><div class="pr-star-v4 pr-star-v4-100-filled"></div><div class="pr-star-v4 pr-star-v4-100-filled"></div><div class="pr-star-v4 pr-star-v4-100-filled"></div></div><div aria-hidden="true" class="pr-snippet-rating-decimal">5.0</div></div></div><div class="pr-category-snippet__total pr-category-snippet__item">1 Review</div></div></section></div></div>
                                                                </div> 

                                                            </div>
                                                            <div class="overview">
                                                                                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>70</bdi></span>                                                            </div>
                                                            
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-face-oil/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                </div>
                                </div>
                                                            <div class="tab-pane fade " id="list-acne-blemishes" role="tabpanel" aria-labelledby="acne-blemishes-list">
                                    <div class="row cs-content">
                                                                                        <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card concer-product-block">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            
                                                            <a href="https://www.carrotstick.com/product/the-acne-spot-treatment-serum/" class="img">
                                                                                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2022/06/The-Acne-Spot-Serum-300x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-acne-spot-treatment-serum/" class="name">
                                                                The Acne Spot Treatment Serum                                                            </a>
                                                            <div class="new-product-rating"> 
                                                                <div class="review-stars-cntnr">
                                                                     <div id="category-snippet-12863" data-pr-component="CategorySnippet" data-pr-page_id="CS-ACNESPOTSERUM" data-pr-mounted="true"><div class="p-w-r" ><section id="pr-category-snippets-CS-ACNESPOTSERUM" class="pr-no-reviews" aria-labelledby="pr-En2zgbr71zI12I5Evu6kR" data-testid="category-snippet"><div class="pr-snippet pr-category-snippet"><div class="pr-category-snippet__rating pr-category-snippet__item"><div class="pr-snippet-stars pr-snippet-stars-png" role="img" aria-label="Rated 0.0 out of 5 stars"><div aria-hidden="true" class="pr-rating-stars"><div class="pr-star-v4 pr-star-v4-0-filled"></div><div class="pr-star-v4 pr-star-v4-0-filled"></div><div class="pr-star-v4 pr-star-v4-0-filled"></div><div class="pr-star-v4 pr-star-v4-0-filled"></div><div class="pr-star-v4 pr-star-v4-0-filled"></div></div><div aria-hidden="true" class="pr-snippet-rating-decimal">0.0</div></div></div><div class="pr-category-snippet__total pr-category-snippet__item">No Reviews</div></div></section></div></div>
                                                                </div> 

                                                            </div>
                                                            <div class="overview">
                                                                <span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;This potent, yet gentle treatment serum allows you to target and clear blemishes and spots. Formulated with 2% blemish-fighting Salicylic Acid along with soothing and redness reducing Chamomile Flower and Green Tea Leaf Extracts. This topical treatment serum will reduce the appearance of redness and inflammation while it gently exfoliates the area, whisking away dirt and bacteria. Formulated with love for all skin types, including acne prone.\n\nWARNINGS: For external use only. Avoid eye area. When using this product, avoid using with topical acne medications at the same time as skin irritation and dryness is more likely to occur. If irritation occurs, only use one topical acne medication at a time. Keep out of reach of children. If swallowed get medical or contact a poison control center immediately. Do not apply to broken skin.\n&quot;}" data-sheets-userformat="{&quot;2&quot;:4480,&quot;10&quot;:0,&quot;11&quot;:4,&quot;15&quot;:&quot;Arial&quot;}">This potent, yet gentle treatment serum allows you to target and clear...</span>                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>50</bdi></span>                                                            </div>
                                                            
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-acne-spot-treatment-serum/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card concer-product-block">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            
                                                            <a href="https://www.carrotstick.com/product/the-water-gel-moisturizer/" class="img">
                                                                                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2022/03/WaterGelMoisturizer@6x-240x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-water-gel-moisturizer/" class="name">
                                                                The Water Gel Moisturizer                                                            </a>
                                                            <div class="new-product-rating"> 
                                                                <div class="review-stars-cntnr">
                                                                     <div id="category-snippet-8674" data-pr-component="CategorySnippet" data-pr-page_id="CS-WATERGELMOIST" data-pr-mounted="true"><div class="p-w-r" ><section id="pr-category-snippets-CS-WATERGELMOIST" class="pr-no-reviews" aria-labelledby="pr-utGkwqQT1vrXQDAv2YPR1" data-testid="category-snippet"><div class="pr-snippet pr-category-snippet"><div class="pr-category-snippet__rating pr-category-snippet__item"><div class="pr-snippet-stars pr-snippet-stars-png" role="img" aria-label="Rated 0.0 out of 5 stars"><div aria-hidden="true" class="pr-rating-stars"><div class="pr-star-v4 pr-star-v4-0-filled"></div><div class="pr-star-v4 pr-star-v4-0-filled"></div><div class="pr-star-v4 pr-star-v4-0-filled"></div><div class="pr-star-v4 pr-star-v4-0-filled"></div><div class="pr-star-v4 pr-star-v4-0-filled"></div></div><div aria-hidden="true" class="pr-snippet-rating-decimal">0.0</div></div></div><div class="pr-category-snippet__total pr-category-snippet__item">No Reviews</div></div></section></div></div>
                                                                </div> 

                                                            </div>
                                                            <div class="overview">
                                                                <span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;This multi-action gel moisturizer targets excess shine, blemishes and prominent pores, while helping to support more even-toned skin. Designed for normal-to-oily and breakout-prone skin types, the lightweight formula includes 10% niacinamide to balance sebum production, promote elasticity and bring flawless clarity to the skin. The bouncy, jelly-like texture creates a light veil of moisture while brightening dull skin and delivering an even, healthy appearance. Formulated with love for normal, dry, oily and combination skin. &quot;}" data-sheets-userformat="{&quot;2&quot;:4481,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:0,&quot;11&quot;:4,&quot;15&quot;:&quot;Arial&quot;}">This multi-action gel moisturizer targets excess shine, blemishes...</span>                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>60</bdi></span>                                                            </div>
                                                            
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-water-gel-moisturizer/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card concer-product-block">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            
                                                            <a href="https://www.carrotstick.com/product/the-exfoliating-peel/" class="img">
                                                                                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2021/12/TheExfoliatingPeel@6x-240x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-exfoliating-peel/" class="name">
                                                                The Exfoliating Peel                                                            </a>
                                                            <div class="new-product-rating"> 
                                                                <div class="review-stars-cntnr">
                                                                     <div id="category-snippet-4958" data-pr-component="CategorySnippet" data-pr-page_id="CS-EXFOLIATGPEEL" data-pr-mounted="true"><div class="p-w-r" ><section id="pr-category-snippets-CS-EXFOLIATGPEEL" class="pr-no-reviews" aria-labelledby="pr-nlZQhy7NKKaHg8xGfz5Fq" data-testid="category-snippet"><div class="pr-snippet pr-category-snippet"><div class="pr-category-snippet__rating pr-category-snippet__item"><div class="pr-snippet-stars pr-snippet-stars-png" role="img" aria-label="Rated 0.0 out of 5 stars"><div aria-hidden="true" class="pr-rating-stars"><div class="pr-star-v4 pr-star-v4-0-filled"></div><div class="pr-star-v4 pr-star-v4-0-filled"></div><div class="pr-star-v4 pr-star-v4-0-filled"></div><div class="pr-star-v4 pr-star-v4-0-filled"></div><div class="pr-star-v4 pr-star-v4-0-filled"></div></div><div aria-hidden="true" class="pr-snippet-rating-decimal">0.0</div></div></div><div class="pr-category-snippet__total pr-category-snippet__item">No Reviews</div></div></section></div></div>
                                                                </div> 

                                                            </div>
                                                            <div class="overview">
                                                                                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>50</bdi></span>                                                            </div>
                                                            
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-exfoliating-peel/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col  cs-col">
                                                    <div class="product-card concer-product-block">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            
                                                            <a href="https://www.carrotstick.com/product/the-face-oil/" class="img">
                                                                                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2021/12/TheFaceOil@6x-240x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-face-oil/" class="name">
                                                                The Face Oil Retinoic                                                            </a>
                                                            <div class="new-product-rating"> 
                                                                <div class="review-stars-cntnr">
                                                                     <div id="category-snippet-4957"></div>
                                                                </div> 

                                                            </div>
                                                            <div class="overview">
                                                                                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>70</bdi></span>                                                            </div>
                                                            
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-face-oil/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                </div>
                                </div>
                                                            <div class="tab-pane fade " id="list-oily-skin" role="tabpanel" aria-labelledby="oily-skin-list">
                                    <div class="row cs-content">
                                                                                        <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card concer-product-block">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            
                                                            <a href="https://www.carrotstick.com/product/the-water-gel-moisturizer/" class="img">
                                                                                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2022/03/WaterGelMoisturizer@6x-240x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-water-gel-moisturizer/" class="name">
                                                                The Water Gel Moisturizer                                                            </a>
                                                            <div class="new-product-rating"> 
                                                                <div class="review-stars-cntnr">
                                                                     <div id="category-snippet-8674"></div>
                                                                </div> 

                                                            </div>
                                                            <div class="overview">
                                                                <span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;This multi-action gel moisturizer targets excess shine, blemishes and prominent pores, while helping to support more even-toned skin. Designed for normal-to-oily and breakout-prone skin types, the lightweight formula includes 10% niacinamide to balance sebum production, promote elasticity and bring flawless clarity to the skin. The bouncy, jelly-like texture creates a light veil of moisture while brightening dull skin and delivering an even, healthy appearance. Formulated with love for normal, dry, oily and combination skin. &quot;}" data-sheets-userformat="{&quot;2&quot;:4481,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:0,&quot;11&quot;:4,&quot;15&quot;:&quot;Arial&quot;}">This multi-action gel moisturizer targets excess shine, blemishes...</span>                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>60</bdi></span>                                                            </div>
                                                            
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-water-gel-moisturizer/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col  cs-col">
                                                    <div class="product-card concer-product-block">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            
                                                            <a href="https://www.carrotstick.com/product/the-exfoliating-peel/" class="img">
                                                                                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2021/12/TheExfoliatingPeel@6x-240x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-exfoliating-peel/" class="name">
                                                                The Exfoliating Peel                                                            </a>
                                                            <div class="new-product-rating"> 
                                                                <div class="review-stars-cntnr">
                                                                     <div id="category-snippet-4958"></div>
                                                                </div> 

                                                            </div>
                                                            <div class="overview">
                                                                                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>50</bdi></span>                                                            </div>
                                                            
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-exfoliating-peel/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                </div>
                                </div>
                                                            <div class="tab-pane fade " id="list-redness-sensitivity" role="tabpanel" aria-labelledby="redness-sensitivity-list">
                                    <div class="row cs-content">
                                                                                        <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card concer-product-block">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            
                                                            <a href="https://www.carrotstick.com/product/the-repair-cream-for-dry-skin/" class="img">
                                                                                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2022/06/The-Repair-Cream-300x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-repair-cream-for-dry-skin/" class="name">
                                                                The Repair Cream. For Dry Skin.                                                            </a>
                                                            <div class="new-product-rating"> 
                                                                <div class="review-stars-cntnr">
                                                                     <div id="category-snippet-12865"></div>
                                                                </div> 

                                                            </div>
                                                            <div class="overview">
                                                                <span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Experience a skincare cream, uniquely formulated to nurture and protect skin’s microbiome. The microbiome is the ecosystem of microbes that live on the skin surface. Happy microbes equal a healthy microbiome and a healthy microbiome equals healthier-looking, more radiant skin. If it becomes imbalanced, acne, eczema, dryness and other skin issues can appear. Our proprietary formulation of probiotics and natural, soothing plant extracts keep the microbiome operating at peak performance and skin looking hydrated and visible issues diminished. Fragrance Free. Formulated with love for all skin types, especially dry.\n&quot;}" data-sheets-userformat="{&quot;2&quot;:4480,&quot;10&quot;:0,&quot;11&quot;:4,&quot;15&quot;:&quot;Arial&quot;}">Experience a skincare cream, uniquely formulated to nurture and... </span>                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>60</bdi></span>                                                            </div>
                                                            
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-repair-cream-for-dry-skin/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card concer-product-block">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            
                                                            <a href="https://www.carrotstick.com/product/the-mineral-sunscreen-spf-50-for-face/" class="img">
                                                                                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2022/06/The-Mineral-Sunscreen-SPF-50-1-1-300x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-mineral-sunscreen-spf-50-for-face/" class="name">
                                                                The Mineral Sunscreen SPF 50                                                            </a>
                                                            <div class="new-product-rating"> 
                                                                <div class="review-stars-cntnr">
                                                                     <div id="category-snippet-12343"></div>
                                                                </div> 

                                                            </div>
                                                            <div class="overview">
                                                                <span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;This Broad Spectrum SPF 50 Facial Lotion is derived from and formulated with mineral sunscreen; there are no harsh chemicals that can harm skin and the environment, including our oceans. Specially formulated with Titanium Dioxide and Zinc Oxide to protect skin against the harmful effects of UVA and UVB which can hasten the effects of skin aging, it also contains natural hydrators and antioxidants including Vitamin E, Green Tea Extract and Hawaiian Kukui Nut Oil to keep skin nourished and smooth. No greasy feel after application and the water-resistant formula delivers protection throughout the day with reapplication. Formulated with love for all skin types.\n-Helps prevent sunburn\n-If used as directed with other sun protection measures (see Directions), deceases the risk of skin cancer and early skin aging caused by the sun.\n\nWARNINGS: For external use only. When using this product, keep out of eyes. Rinse with warm water to remove. Do not use on damaged or broken skin. Stop use and consult a doctor if irritation or rash occurs. Keep out of the reach of children. If swallowed, get medical help or contact a Poison Control Center immediately. Note: If allergy-prone, pregnant, or lactating, consult your physician before use. \n&quot;}" data-sheets-userformat="{&quot;2&quot;:4481,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:0,&quot;11&quot;:4,&quot;15&quot;:&quot;Arial&quot;}">This Broad Spectrum SPF 50 Facial Lotion is derived from and formulated...</span>                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>60</bdi></span>                                                            </div>
                                                            
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-mineral-sunscreen-spf-50-for-face/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col last-big-card  cs-col">
                                                    <div class="product-card concer-product-block">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            
                                                            <a href="https://www.carrotstick.com/product/the-gentle-hydrating-cleanser/" class="img">
                                                                                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2022/06/The-Gentle-Hydrating-Cleanser-300x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-gentle-hydrating-cleanser/" class="name">
                                                                The Gentle Hydrating Cleanser                                                            </a>
                                                            <div class="new-product-rating"> 
                                                                <div class="review-stars-cntnr">
                                                                     <div id="category-snippet-12662"></div>
                                                                </div> 

                                                            </div>
                                                            <div class="overview">
                                                                <span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;This gentle and effective oil-free cleanser is formulated with natural plant extracts and natural hydrators, including hyaluronic acid, that won’t strip precious oils, disrupting the surface barrier. Coconut-based surfactants work into a luxurious lather. Skin will look and feel nourished and soft to the touch. Antioxidant-packed ingredients help protect against environmental stressors that can cause the appearance of fine lines and wrinkles. Does not contain harsh sulfates that can irritate skin. Fragrance Free. Formulated with love for all skin types, including sensitive.\n&quot;}" data-sheets-userformat="{&quot;2&quot;:4481,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:0,&quot;11&quot;:4,&quot;15&quot;:&quot;Arial&quot;}">This gentle and effective oil-free cleanser is formulated with natural...</span>                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>40</bdi></span>                                                            </div>
                                                            
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-gentle-hydrating-cleanser/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                </div>
                                </div>
                                                            <div class="tab-pane fade " id="list-dull-uneven-skin-tone" role="tabpanel" aria-labelledby="dull-uneven-skin-tone-list">
                                    <div class="row cs-content">
                                                                                        <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card concer-product-block">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            
                                                            <a href="https://www.carrotstick.com/product/the-mineral-sunscreen-spf-50-for-face/" class="img">
                                                                                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2022/06/The-Mineral-Sunscreen-SPF-50-1-1-300x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-mineral-sunscreen-spf-50-for-face/" class="name">
                                                                The Mineral Sunscreen SPF 50                                                            </a>
                                                            <div class="new-product-rating"> 
                                                                <div class="review-stars-cntnr">
                                                                     <div id="category-snippet-12343"></div>
                                                                </div> 

                                                            </div>
                                                            <div class="overview">
                                                                <span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;This Broad Spectrum SPF 50 Facial Lotion is derived from and formulated with mineral sunscreen; there are no harsh chemicals that can harm skin and the environment, including our oceans. Specially formulated with Titanium Dioxide and Zinc Oxide to protect skin against the harmful effects of UVA and UVB which can hasten the effects of skin aging, it also contains natural hydrators and antioxidants including Vitamin E, Green Tea Extract and Hawaiian Kukui Nut Oil to keep skin nourished and smooth. No greasy feel after application and the water-resistant formula delivers protection throughout the day with reapplication. Formulated with love for all skin types.\n-Helps prevent sunburn\n-If used as directed with other sun protection measures (see Directions), deceases the risk of skin cancer and early skin aging caused by the sun.\n\nWARNINGS: For external use only. When using this product, keep out of eyes. Rinse with warm water to remove. Do not use on damaged or broken skin. Stop use and consult a doctor if irritation or rash occurs. Keep out of the reach of children. If swallowed, get medical help or contact a Poison Control Center immediately. Note: If allergy-prone, pregnant, or lactating, consult your physician before use. \n&quot;}" data-sheets-userformat="{&quot;2&quot;:4481,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:0,&quot;11&quot;:4,&quot;15&quot;:&quot;Arial&quot;}">This Broad Spectrum SPF 50 Facial Lotion is derived from and formulated...</span>                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>60</bdi></span>                                                            </div>
                                                            
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-mineral-sunscreen-spf-50-for-face/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card concer-product-block">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            
                                                            <a href="https://www.carrotstick.com/product/the-honey-amber-exfoliating-polish/" class="img">
                                                                                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2022/02/The-Honey-Amber-Exfoliating-Polish-300x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-honey-amber-exfoliating-polish/" class="name">
                                                                The Honey Amber Exfoliating Polish                                                            </a>
                                                            <div class="new-product-rating"> 
                                                                <div class="review-stars-cntnr">
                                                                     <div id="category-snippet-9453" data-pr-component="CategorySnippet" data-pr-page_id="CS-HONEYAMBEREXP" data-pr-mounted="true"><div class="p-w-r" ><section id="pr-category-snippets-CS-HONEYAMBEREXP" class="" aria-labelledby="pr-vqxI92S9KXZmUXz3I27xe" data-testid="category-snippet"><div class="pr-snippet pr-category-snippet"><div class="pr-category-snippet__rating pr-category-snippet__item"><div class="pr-snippet-stars pr-snippet-stars-png" role="img" aria-label="Rated 5.0 out of 5 stars"><div aria-hidden="true" class="pr-rating-stars"><div class="pr-star-v4 pr-star-v4-100-filled"></div><div class="pr-star-v4 pr-star-v4-100-filled"></div><div class="pr-star-v4 pr-star-v4-100-filled"></div><div class="pr-star-v4 pr-star-v4-100-filled"></div><div class="pr-star-v4 pr-star-v4-100-filled"></div></div><div aria-hidden="true" class="pr-snippet-rating-decimal">5.0</div></div></div><div class="pr-category-snippet__total pr-category-snippet__item">2 Reviews</div></div></section></div></div>
                                                                </div> 

                                                            </div>
                                                            <div class="overview">
                                                                                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>50</bdi></span>                                                            </div>
                                                            
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-honey-amber-exfoliating-polish/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card concer-product-block">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            
                                                            <a href="https://www.carrotstick.com/product/the-water-gel-moisturizer/" class="img">
                                                                                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2022/03/WaterGelMoisturizer@6x-240x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-water-gel-moisturizer/" class="name">
                                                                The Water Gel Moisturizer                                                            </a>
                                                            <div class="new-product-rating"> 
                                                                <div class="review-stars-cntnr">
                                                                     <div id="category-snippet-8674"></div>
                                                                </div> 

                                                            </div>
                                                            <div class="overview">
                                                                <span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;This multi-action gel moisturizer targets excess shine, blemishes and prominent pores, while helping to support more even-toned skin. Designed for normal-to-oily and breakout-prone skin types, the lightweight formula includes 10% niacinamide to balance sebum production, promote elasticity and bring flawless clarity to the skin. The bouncy, jelly-like texture creates a light veil of moisture while brightening dull skin and delivering an even, healthy appearance. Formulated with love for normal, dry, oily and combination skin. &quot;}" data-sheets-userformat="{&quot;2&quot;:4481,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:0,&quot;11&quot;:4,&quot;15&quot;:&quot;Arial&quot;}">This multi-action gel moisturizer targets excess shine, blemishes...</span>                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>60</bdi></span>                                                            </div>
                                                            
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-water-gel-moisturizer/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card concer-product-block">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            
                                                            <a href="https://www.carrotstick.com/product/the-exfoliating-peel/" class="img">
                                                                                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2021/12/TheExfoliatingPeel@6x-240x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-exfoliating-peel/" class="name">
                                                                The Exfoliating Peel                                                            </a>
                                                            <div class="new-product-rating"> 
                                                                <div class="review-stars-cntnr">
                                                                     <div id="category-snippet-4958"></div>
                                                                </div> 

                                                            </div>
                                                            <div class="overview">
                                                                                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>50</bdi></span>                                                            </div>
                                                            
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-exfoliating-peel/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col-12 col-md-6  cs-col">
                                                    <div class="product-card concer-product-block">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            
                                                            <a href="https://www.carrotstick.com/product/the-face-oil/" class="img">
                                                                                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2021/12/TheFaceOil@6x-240x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-face-oil/" class="name">
                                                                The Face Oil Retinoic                                                            </a>
                                                            <div class="new-product-rating"> 
                                                                <div class="review-stars-cntnr">
                                                                     <div id="category-snippet-4957"></div>
                                                                </div> 

                                                            </div>
                                                            <div class="overview">
                                                                                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>70</bdi></span>                                                            </div>
                                                            
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-face-oil/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                            <div class="col  cs-col">
                                                    <div class="product-card concer-product-block">
                                                        <div class="d-flex flex-column justify-content-center product-content">
                                                            
                                                            <a href="https://www.carrotstick.com/product/the-brightening-pads/" class="img">
                                                                                                                                <img src="https://www.carrotstick.com/wp-content/uploads/2021/11/BrighteningPads_@6x-240x300.png" class="img-fluid"/>
                                                            </a>
                                                            <a href="https://www.carrotstick.com/product/the-brightening-pads/" class="name">
                                                                The Brightening Pads                                                            </a>
                                                            <div class="new-product-rating"> 
                                                                <div class="review-stars-cntnr">
                                                                     <div id="category-snippet-16" data-pr-component="CategorySnippet" data-pr-page_id="CS-BRIGHTENINGPD" data-pr-mounted="true"><div class="p-w-r" ><section id="pr-category-snippets-CS-BRIGHTENINGPD" class="" aria-labelledby="pr-VyMfZihOiT03NSsGarag8" data-testid="category-snippet"><div class="pr-snippet pr-category-snippet"><div class="pr-category-snippet__rating pr-category-snippet__item"><div class="pr-snippet-stars pr-snippet-stars-png" role="img" aria-label="Rated 4.8 out of 5 stars"><div aria-hidden="true" class="pr-rating-stars"><div class="pr-star-v4 pr-star-v4-100-filled"></div><div class="pr-star-v4 pr-star-v4-100-filled"></div><div class="pr-star-v4 pr-star-v4-100-filled"></div><div class="pr-star-v4 pr-star-v4-100-filled"></div><div class="pr-star-v4 pr-star-v4-75-filled"></div></div><div aria-hidden="true" class="pr-snippet-rating-decimal">4.8</div></div></div><div class="pr-category-snippet__total pr-category-snippet__item">9 Reviews</div></div></section></div></div>
                                                                </div> 

                                                            </div>
                                                            <div class="overview">
                                                                Reveal a fresh, smooth and radiant complexion with...                                                            </div>
                                                            <div class="price">
                                                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>60</bdi></span>                                                            </div>
                                                            
                                                            <div class="quick-view-btn">
                                                                <a href="https://www.carrotstick.com/product/the-brightening-pads/" class="btn btn-lg">
                                                                    View product
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                </div>
                                </div>
                                                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>

</Layout>
)
 
}

export default ConcernList